<template>
  <div class="assignConsultArea fill-height ">
    <div class="circleOne"></div>
    <div class="circleTwo"></div>
    <div class="circleThree"></div>

    <v-app-bar class="flex-navigation" style="z-index: 2;padding:5px 0" v-if="$vuetify.breakpoint.mdAndUp" elevation="0"
               color="transparent">
      <v-container>
        <v-row no-gutters>
          <v-col class="d-flex justify-start">
          </v-col>
          <v-col class="text-center">
            <router-link :to="{name:'dashboard'}">
              <v-toolbar-title>
                <img :src="require('../../assets/simialogopng.png')" style="width: 125px;"
                     class="mt-4 " v-if="getPlatformInfo('id') == 3">
                <p v-else-if="getPlatformInfo('id') == 4">
                  {{ getPlatformTitle() }}
                </p>
              </v-toolbar-title>
            </router-link>
          </v-col>
          <v-col class="d-flex justify-end">

          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <div class="" v-else>
      <v-app-bar class="mobileAppBar" height="50">
        <v-row>
          <v-col class="text-right">
          </v-col>
          <v-col class="text-center">
            <router-link :to="{name:'dashboard'}">
              <v-toolbar-title>
                <img :src="require('../../assets/simialogopng.png')" style="width: 125px;"
                     class="mt-2 mr-2" v-if="getPlatformInfo('id') == 3">
                <p v-else-if="getPlatformInfo('id') == 4">
                  {{ getPlatformTitle() }}
                </p>
              </v-toolbar-title>
            </router-link>
          </v-col>
          <v-col :class="$vuetify.rtl ? 'text-left' : 'text-right'">

          </v-col>
        </v-row>
      </v-app-bar>
    </div>
    <v-container>
      <div class="responseArea">
        <div class="responseArea-inner pa-5">
          <div class="contentArea text-center py-10">
            <img src="../../assets/new-design/successPayment.svg" width="100%" alt="">
            <template v-if="!status">
              <h3>
                پرداخت در حال بررسی است
                <br>
                لطفا صبر کنید
              </h3>
              <v-progress-circular indeterminate class="mt-5" color="#46b1a1"></v-progress-circular>
            </template>
            <template v-else-if="status == 'user_action'">
              <div class="loginBtn" @click="processPayment($route.query.type)">
                بررسی مجدد پرداخت
              </div>
            </template>
          </div>

        </div>
      </div>
    </v-container>

  </div>
</template>

<script>
/*eslint-disable*/
// import Vue from 'vue';
// import Tracker from '../../tracker';

import afterPaymentMixins from "@/mixins/afterPaymentMixins";
import axios from "axios";

export default {

  components: {},
  mixins: [afterPaymentMixins],
  mounted() {
    if (this.$route.query.hasOwnProperty('wait')) {
      setTimeout(() => {
        this.timer = setInterval(() => {
          this.timeHandler()
        }, 2000)
      }, this.$route.query.wait)
    } else {
      this.timer = setInterval(() => {
        this.timeHandler()
      }, 2000)
    }

  },
  methods: {
    processPayment(type) {
      this.status = null
      let action = type == 'revolut' ? 'processPaymentRedirectRevolut' : 'processPaymentRedirectShopify'
      this.$store.dispatch(action).then((resp) => {
        if (resp.redirectUrl != null) {
          window.location.href = resp.redirectUrl;
        } else {
          if (this.$route.query.hasOwnProperty('type') && this.$route.query.type == 'revolut') {
            this.status = 'user_action'
          }
        }
      })
    },
    timeHandler() {
      if (this.$route.query.type) {
        this.processPayment(this.$route.query.type)
      } else {
        this.processPayment('shopify')
      }
      this.retry--;
      if (this.retry < 0) {
        if (this.$route.query.hasOwnProperty('type') && this.$route.query.type == 'revolut') {
          clearInterval(this.timer);
          this.status = 'user_action'
        } else {
          clearInterval(this.timer);
          if (this.platFormInfoLS?.id == 3) {
            window.location.href = 'https://app.simiaroom.com'
          } else {
            window.location.href = 'https://app.moshaver.live'
          }
        }

      }
    }
  },
  data() {
    return {
      retry: 5,
      timer: null,
      status: 'user_action'
    }
  }
}
</script>

<style>
@media (min-width: 200px) and (max-width: 600px) {
  .circleOne {
    width: 300px !important;
    height: 300px !important;
    top: -150px !important;
    left: -160px !important;
  }

  .contentArea {
    width: 80% !important;
  }

}

.description {
  font-size: 9pt;
  line-height: 20pt;
}

.assignConsultArea {
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  position: relative;
}

.circleOne {
  background: #dff8ff;
  z-index: 0;
  width: 800px;
  height: 800px;
  border-radius: 50%;
  top: -300px;
  left: -300px;
  position: absolute;
  filter: blur(20px);
}

.circleTwo {
  position: absolute;
  background: #f0f1ff;
  bottom: -250px;
  left: -140px;
  width: 450px;
  height: 450px;
  border-radius: 50%;
  filter: blur(20px);
}

.circleThree {
  position: absolute;
  background: #e0fff6;
  bottom: 200px;
  right: -150px;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  filter: blur(20px);
}

.loginBtn {
  width: 160px !important;
  height: 34px !important;
  padding: 5px;
  border-radius: 10px;
  border: solid 1px #46b1a1 !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  /*color: #46b1a1 !important;*/
  background: #46b1a1;
  color: white;
  margin: 20px auto 0px auto;
}

.mobileAppBar {
  box-shadow: 0 3px 17px 0 rgba(0, 0, 0, 0.16);
}

.responseArea {
  position: relative;
}

.responseArea-inner {
  position: absolute;
  top: 0px;
  border-radius: 20px;
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;
  right: 0px;
  left: 0px;
}

.successPayment img {
  width: 100%;
}

.contentArea {
  width: 32%;
  margin: auto;
}

.successPayment {

}

.successPayment .successTitle {
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  color: #1cba88;
}

.successItems {
  margin-top: 80px;
}

.finalPrice {
  border-radius: 10px;
  color: #3cbe8b;
  text-align: center;
  margin-top: 25px;
  padding: 15px;
  background: #F3FCF9;
}

.continueBtn {
  height: 50px !important;
  padding: 15px !important;
  margin-top: 50px !important;
  border-radius: 10px;
  background-color: #4576d8 !important;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.failedTitle {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  color: #fe6b2d;
  margin-top: 20px;
}

.failedDeac {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  margin-top: 30px;
}

.returnBtn {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  color: #4576d8 !important;
  height: 50px !important;
  border-radius: 10px;
  border: solid 1px #4576d8 !important;
  background: #fff !important;
  padding: 10px;
  margin-top: 10px;
  width: 100%;
}

</style>